






























































































































































































































































































































































































































































































































































.room {
  position: relative;
  width: 90%;
  height: 800px;
  margin: 0 auto;

  .close {
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 9px;
    color: #fff;
    z-index: 999;
  }
  .prism-player {
    width: 100%;
    height: 100%;
    /* opacity: 0; */
    /* display: none; */
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .chatContent {
    position: absolute;
    left: 0;
    bottom: 45px;
    width: 500px;
    height: 100px;
    border: 0px #fff solid;
    z-index: 999;
    overflow-y: scroll;

    .chartItemContent {
      margin: 5px 0;
    }
    .chartItem {
      color: #fff;
      height: 35px;
      border-radius: 2px;
      background: rgb(100 145 105 / 30%);
      padding: 0 8px;
      margin: 5px 0;
      .userNick {
        color: pink;
        margin-right: 6px;
      }
    }
  }
}
.sendCotent {
  position: absolute;
  display: flex;
  justify-content: space-between;
  z-index: 9999;
  bottom: 0;
  width: 70%;
  .btnStop {
    cursor: pointer;
    background: #544a4c;
    width: 100px;
    z-index: 9999;
    color: #fff;
    border-radius: 0 5px 5px 0;
    text-align: center;
    line-height: 35px;
  }
}
.sendCotent ::v-deep .el-input__inner {
  background: rgb(100 145 105 / 30%);
  border: 0;
  border-radius: 0;
}
#videoElement {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
}
